import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { graphql, Link, StaticQuery } from "gatsby";

import Hero from "../../components/landingPage/Hero";
import Layout from "../../components/Layout";
import Cta from "../../components/Cta";

import Location from "../../components/landingPage/Location";
import PriceList from "../../components/landingPage/PriceList";
import Dermoscopy from "../../components/landingPage/Dermoscopy";
import Oncology from "../../components/landingPage/Oncology";
import Videodermoscopy from "../../components/landingPage/Videodermoscopy";
import Surgery from "../../components/landingPage/Surgery";
import Abcde from "../../components/landingPage/Abcde";
import Gallery from "../../components/landingPage/Gallery";
import Offer from "../../components/landingPage/Offer";
import RiskGroup from "../../components/landingPage/RiskGroup";
import Covid from "../../components/landingPage/Covid";
import Contact from "../../components/landingPage/Contact";
import {
  GTM_WIDEO,
  GTM_CHIRURGIA,
  GTM_E_ONKOLOGIA,
  GTM_ONKOLOGIA,
  GTM_LEAD_UMOW_SIE,
} from "../../config/gtm_classes";

const GTM_LOCATION = "poznan-onkologia";

//styles
import "../../styles/home.scss";

import SEO from "../../components/SEO";
import CallCta from "../../components/CallCta";
import ButtonDrw from "../../components/landingPage/ButtonDrw";
import MelanomaOncology from "../../components/landingPage/MelanomaOncology";
import MelanomaTreatment from "../../components/landingPage/MelanomaTreatment";
import Doctors from "../../components/landingPage/Doctors";
import ButtonApptNow from "../../components/landingPage/ButtonApptNow";

const REVIEWS = [
  {
    author: "Jerzy W.",
    city: "Poznań",
    review:
      "Kompetentni i wykwalifikowani pracownicy. Jedyne takie miejsce w Poznaniu, gdzie bez problemu można umówić się na badanie wideodermatoskopowe. Przemiła Pani odbierająca telefon - wszystko mi wytłumaczyła. Dzięki.",
  },
  {
    author: "Sandra K.",
    city: "Poznan",
    review:
      "Już dwa razy korzystałam z wizyty w tym miejscu. Za każdym razem bardzo profesjonalnie. Badanie znamion w jednej cenie pomimo tego, że mam mnóstwo znamion i badanie zajęło godzinę! Pani doktor wymroziła mi część zmian. Polecam.",
  },
  {
    author: "Emilia M.",
    city: "Poznań",
    review:
      "W życiu nie spotkałam się podczas wizyty u lekarza, aby ktoś był tak zainteresowany pacjentem jak Pani doktor. Podczas konsultacji na spokojnie przejrzała moje wyniki badań, obejrzała moje znamię i dopiero wtedy zajęła się jego badaniem. Znamię kwalifikowało się do usunięcia, więc to zrobiłam.",
  },
];

const PRICES = [
  {
    isOpened: true,
    categoryName: "Konsultacje onkologiczne - czerniak",
    items: [
      {
        button: () => (
          <ButtonApptNow
            className={`${GTM_LOCATION} ${GTM_ONKOLOGIA}`}
            data-apptnow-widget="d2486d20-97da-11ed-b366-89b05425b063"
          />
        ),
        price: "250",
        label: "konsultacja onkologiczna - nowotwory skóry",
        sub:
          "ponowna konsultacja onkologiczna (w przeciągu 6 miesięcy): 180 zł",
      },
      {
        button: () => (
          <ButtonApptNow
            className={`${GTM_LOCATION} ${GTM_E_ONKOLOGIA}`}
            data-apptnow-widget="57c029a0-9b25-11ed-b366-89b05425b063"
          />
        ),
        price: "200",
        label: "e-wizyta onkologiczna - nowotwory skóry",
        sub: "wideokonferencja",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Badanie znamion - wideodermatoskopia",
     
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_WIDEO}`}
        data-apptnow-widget="f40be0f0-97d9-11ed-b366-89b05425b063"
      />
    ),
    items: [
      {
        price: "450",
        label: "wideodermatoskopia wszystkich znamion - osoba dorosła ",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "350",
        label:
          "wideodermatoskopia wszystkich znamion - dziecko do 12 roku życia",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "250",
        label:
          "wideodermatoskopia - wizyta kontrolna do 6 mies. od pełnego badania",
        sub:
          "badanie wytypowanych przez lekarza na poprzedniej wizycie znamion do monitorowania",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Chirurgiczne usuwanie znamion",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_CHIRURGIA}`}
        data-apptnow-widget="520e0a70-97da-11ed-b366-89b05425b063"
      />
    ),
    items: [
      {
        price: "200",
        label: "konsultacja chirurgiczna",
        sub: "zabiegi chirurgiczne są wykonywane u pacjentów pełnoletnich",
      },
      {
        price: "550",
        label: "usunięcie 1 znamienia do 1,5cm",
        sub:
          "w standardowej lokalizacji - tułów/kończyny - w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "330",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty do 1,5cm",
        sub: "w standardowej lokalizacji - tułów/kończyny",
      },
      {
        price: "650",
        label:
          "usunięcie 1 znamienia powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
        sub: "w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "380",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
      },
      {
        price: "150",
        label: "badanie histopatologiczne jednej zmiany",
      },
      {
        price: "500",
        label: "chirurgia aparatu paznokciowego",
      },
    ],
  },
];

const OFFERS = [
  {
    type: "onkologia",
    title: "Konsultacje onkologiczne",
    desc:
      "Konsultacja onkologiczna pacjentów z rozpoznaniem czerniaka złośliwego. Pacjent otrzymuje zalecenia dotyczące badań diagnostycznych, leczenia uzupełniającego, a także najskuteczniejszych metod leczenia zaawansowanego czerniaka. ",
      link:"#cennik",
  },
  {
    type: "badanie",
    title: "Badanie znamion",
    desc:
      "U pacjentów  z rozpoznaniem czerniaka istnieje duże prawdopodobieństwo rozwoju drugiego niezależnego nowotworu skóry dlatego osoby obciążone chorobą oraz ich najbliższa rodzina wymagają regularnych badań dermatoskopowych.",
      link:"/poznan/dermatoskopowe-badanie-znamion-wideodermatoskopia",
  },
  {
    type: "chirurgiczne",
    title: "Usuwanie znamion",
    desc:
      "Każdy podejrzany pieprzyk wytypowany przez lekarza w trakcie badania wideodermatoskopowego powinien zostać usunięty chirurgicznie z wykonaniem histopatologicznej oceny usuniętej zmiany skórnej. Dopiero na tej podstawie można postawić ostateczne rozpoznanie i zdecydować o dalszym leczeniu. ",
      link:"/poznan/usuwanie-znamion-pieprzykow",
  },
];

const INFOS = [
  {
    title: "Ile trwa badanie?",
    desc:
      "Badanie wszystkich znamion - 30-40 min. Badanie kontrolne znamion wyznaczonych przez lekarza - 15-20 min.",
  },
  {
    title: "Jak się przygotować do badania?",
    desc:
      "Przed badaniem należy wykonać demakijaż oraz skrócić owłosienia w miejscach, w których znajdują się znamiona, celem poprawy jakości obrazu dermatoskopowego.",
  },
  {
    title: "Jakim urządzeniem badamy?",
    desc:
      "Pacjent badany jest za pomocą nowoczesnego sprzętu - wideodermatoskopu Fotofinder Portable Medicam 1000 Full HD",
  },
];

const CtaUmowSie = () => {
  return (
    <AnchorLink href="#cennik" title="Umów się" className={GTM_LEAD_UMOW_SIE}>
      Umów się
    </AnchorLink>
  );
};
const CtaSecondary = () => {
  return (
    <Link href="/poznan/dermatoskopowe-badanie-znamion-wideodermatoskopia" title="Wideodermatoskopia - Badanie Znamion Poznań">
      Zbadaj Znamiona
    </Link>
  );
};

const PoznanBadanieZnamionPage = ({ graph, location }) => {
  const FAQS = [
    {
      image: graph.faqs[1].image,
      imageMobile: graph.faqs[1].imageMobile,
      title:
        "Dotychczas musiałem dopłacać do każdego zbadanego znamienia. Jak to wygląda tutaj?",
      text:
        "W Centrum Twoje Znamiona zostaniesz zbadany od stóp do głów. Lekarz obejrzy każde znamię na Twojej skórze, a koszt badania nie jest uzależniony od liczby znamion. Istotą badania dermatoskopowego jest oglądanie całego ciała i archiwizacja obrazu wszystkich atypowych zmian, które należy w przyszłości poddać badaniu kontrolnemu. Oglądanie jedynie pojedynczych, niepokojących zmian na skórze nie jest pełnowartościowym badaniem profilaktycznym i nie zwalnia z konieczności wykonania pełnej diagnostyki.",
    },
    {
      image: graph.faqs[2].image,
      imageMobile: graph.faqs[2].imageMobile,
      title: "Jak przygotować się do badania znamion?",
      text:
        "Panie powinny dokonać bardzo dokładnego demakijażu twarzy, gdyż nawet delikatny podkład jest doskonale widoczny w dużym powiększeniu i zaburza obraz badanych zmian skórnych. Panowie natomiast powinni skrócić owłosienie lub ogolić się w miejscach, gdzie posiadają znamiona. Pacjent w trakcie badania zostanie poproszony przez lekarza do rozebrania się do bielizny celem dokładnego obejrzenia wszystkich znamion na ciele. ",
    },
  ];

  return (
    <Layout constactCtaComponent={<CtaUmowSie />} location={location}>
      <SEO
        title="Konsultacje Onkologiczne w Poznaniu - leczenie czerniaka - twojeznamiona.pl"
        description="Konsultacje onkologiczne pacjentów z rozpoznaniem czerniaka złośliwego w Poznaniu. Porozmawiaj o dostępnych metodach leczenia z onkologiem specjalizującym się w leczeniu czerniaka, skonsultuj wyniki badań, dowiedź się jakie są nowoczesne formy leczenia.  Osoby po usunięciu czerniaka i ich najbliższa rodzina powinny regularnie badać znamiona. Wcześnie wykryty czerniak może być całkowicie wyleczony!"
        keywords="czerniak, leczenie czerniaka, leczenie czerniaka poznań, konsultacje onkologiczne poznań, łukasz galus,  badania kliniczne czerniak"
      />
      <Hero
        h1="Konsultacje onkologiczne. Diagnostyka i leczenie czerniaka"
        oneLiner="Wszystkie swoje działania skupiamy na diagnostyce czerniaka aby zmniejszyć umieralność z powodu nowotworów skóry Polsce."
        oneLinerMore="Dowiedz się jakie są najlepsze metody leczenia czerniaka i upewnij się, że Twoja terapia jest odpowiednio zaplanowana."
        ctaPrimary={<CtaUmowSie />}
        ctaSecondary={<CtaSecondary />}
      />
      <Oncology
        className="home_two_col_section_left_img with_no_padding_bottom with_no_padding_top"
        intro="Czerniak złośliwy"
        h2="Konsultacje onkologiczne"
        reverse={true}
        H5={() => (
          <>
            Po rozpoznaniu czerniaka pacjent wymaga dalszej diagnostyki. Na tym
            etapie niezbędna jest konsultacja onkologiczna. Odpowiednie
            zaplanowanie badań diagnostycznych i przebiegu leczenia ma kluczowe
            znaczenie dla efektów terapii.
          </>
        )}
        Text={() => (
          <>
            Skonsultuj się z lekarzem specjalistą onkologii klinicznej, który w
            codziennej praktyce specjalizuje się w diagnostyce i leczeniu
            pacjentów z rozpoznaniem czerniaka. Porozmawiaj o dostępnych
            metodach leczenia oraz o możliwości skorzystania z nowoczesnej
            terapii.
          </>
        )}
        Buttons={() => null}
      />
      <Doctors className="home_doctors" graph={graph} button={CtaUmowSie} />
      <PriceList className="home_price_list" data={PRICES} ifProgramProfilaktykiAvailable/>
      

      <Contact className="home_contact" where="w Poznaniu" city="Poznan" />
      <Location
        className="home_location"
        coords={{
          lat: 52.41529442950558,
          lng: 16.906127930947157,
        }}
        googleMarker={{
          data: ["Dąbrowskiego 77A", "60-101 Poznań"],
          link: "https://g.page/twoje-znamiona-poznan?share",
        }}
        additionalDetails="Wejście do Ośrodka znajduje się w budynku między Biedronką a salonem fryzjerskim. Parking płatny na miejscu."
        locationInfo={[
          "Centrum Medyczne Medify.me",
          "ul. Kościelna 30/60",
          "60-538 Poznań",
        ]}
        hoursInfo={["pon. - pt.: 08:00 - 20:30,", "sob. 9:00-15:00"]}
        transportationInfo={["Przystanek Kościelna Tunel lub Rynek Jeżycki"]}
      />

      <Gallery
        className="home_gallery"
        data={graph.gallery}
        color="yellow"
        noAlert
        introText="Galeria"
        h2="Centrum Badania Znamion w Poznaniu"
        imageTitlePostfix="w Poznaniu"
      />
      <Offer className="home_offer" data={OFFERS} />

      <MelanomaOncology className="home_two_col_section_right_img " />
      <Dermoscopy className="home_two_col_section_right_img " />
      <MelanomaTreatment className="home_two_col_section_right_img " />

      <Abcde className="home_abcde" />
      <RiskGroup className="home_risk_group home_risk_group--onkology" />
      <Videodermoscopy
        className="home_two_col_section_left_img"
        reverse={false}
      />
      <Surgery className="home_two_col_section_left_img with_no_padding_top with_no_padding_bottom" />
      <Cta className="home_cta" constactCtaComponent={<CtaUmowSie />} />
      <CallCta />
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        ImageGallery1: allFile(
          filter: {
            relativePath: { regex: "/Centrum-Badania-Znamion-Poznan_1.jpg/" }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery2: allFile(
          filter: {
            relativePath: { regex: "/Centrum-Badania-Znamion-Poznan_2.jpg/" }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery3: allFile(
          filter: {
            relativePath: { regex: "/Centrum-Badania-Znamion-Poznan_3.png/" }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery4: allFile(
          filter: {
            relativePath: { regex: "/Centrum-Badania-Znamion-Poznan_4.png/" }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1Image: allFile(filter: { relativePath: { regex: "/faq1.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2Image: allFile(filter: { relativePath: { regex: "/faq2.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-1-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-2-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Doctors: allFile(filter: { relativePath: { regex: "/people*/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 128, maxHeight: 128, quality: 100) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <PoznanBadanieZnamionPage
        graph={{
          doctors: data.Doctors.nodes,
          gallery: [
            data.ImageGallery1.nodes[0],
            data.ImageGallery2.nodes[0],
            data.ImageGallery3.nodes[0],
            data.ImageGallery4.nodes[0],
          ],
          faqs: {
            1: {
              image: data.Faq1Image.nodes[0],
              imageMobile: data.Faq1ImageMobile.nodes[0],
            },
            2: {
              image: data.Faq2Image.nodes[0],
              imageMobile: data.Faq2ImageMobile.nodes[0],
            },
          },
        }}
        {...props}
      />
    )}
  />
);
